
.snackbar-error {
  background-color: #d9534f !important;
}

.snackbar-warning {
  background-color: #ff9800 !important;
}

.snackbar-success {
  background-color: #2b9c3e !important;
}

.snackbar-info {
  background-color: #4c7fb9 !important;
}

.snackbar-text {
  background-color: #adadad !important;
}
